import {Link, useNavigate} from "react-router-dom"
import { authenticate } from '../service/authenticate';
import React, { useState } from 'react'
import { ConfigCookies, ConfigRoles } from "../Config";
import { useCookies } from "react-cookie";
import { getRolesByUser } from "../service/TraineeService.js";


export const LoginPage = ()=>{
    const Navigate = useNavigate();
    const imageUrl = `${process.env.PUBLIC_URL}/assets/dhcs-logo-medium.png`;
    const [cookies, setCookie, removeCookie] = useCookies(ConfigCookies.USER_ROLE, ConfigCookies.USER_ID, ConfigCookies.USER_AUTHENTICATED, ConfigCookies.TRAINEE_ID)

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginErr,setLoginErr]=useState('');

    if (cookies.traineeId){
      removeCookie(ConfigCookies.TRAINEE_ID);
    }

    const formInputChange = (formField, value) => {
        if (formField === "email") {
        setEmail(value);
        }
        if (formField === "password") {
        setPassword(value);
        }
    };

    const validation = () => {
        return new Promise((resolve, reject) => {
          if (email === '' && password === '') {
            setLoginErr("Email and Password are required");
            resolve({ email: "Email is Required", password: "Password is required" });
          }
          else if (email === '') {
            setLoginErr("Email is Required")
            resolve({ email: "Email is Required", password: "" });
          }
          else if (password === '') {
            setLoginErr("Password is required")
            resolve({ email: "", password: "Password is required" });
          }
          else if (password.length < 6) {
            setLoginErr("Password must be at least 6 character")
            resolve({ email: "", password: "must be 6 character" });
          }
          else {
            resolve({ email: "", password: "" });
          }
        });
      };

    const handleClick = () => {
        validation()
          .then((res) => {
            if (res.email === '' && res.password === '') {
              authenticate(email.trim(),password)
              .then((data)=>{
                setLoginErr('');
                setCookie(ConfigCookies.USER_AUTHENTICATED, email)
                console.log('Set email', email)
                getRolesByUser(email.trim()).then((result)=>{
                  setCookie(ConfigCookies.USER_ROLE, result.role.name);
                  if (result.role.name===ConfigRoles.TRAINEE){
                    Navigate('../testcase?trainee_id='+result.user.dbId);
                  }else if (result.role.name===ConfigRoles.TRAINER){
                    setCookie(ConfigCookies.USER_ID, result.user._id);
                    Navigate('../dashboard');
                  }else if (result.role.name===ConfigRoles.ADMIN){
                    setCookie(ConfigCookies.USER_ID, result.user._id);
                    Navigate('../users');
                  }else{
                    Navigate('../logout');
                  }
                })                
                
              },(err)=>{
                console.log(err);
                setLoginErr('Invalid User or Password')
              })
              .catch(err=>console.log(err))
            }
          }, err => console.log(err))
          .catch(err => console.log(err));
      }


    return (
        
        <div>
            <div className="navbar"></div>
            <div style={{display:"flex", justifyContent: "center"}}>
              <div style={{display: "flex", width: "50%", backgroundColor: "#f5f5f5", marginLeft: "20px"}}>
                <div style={{width: "40%", }}>
                  <form className="new_user">
                    <div style={{display: "flex", flexDirection: "column"}}>
                      <h2 style={{textAlign: "center"}}>Login</h2>
                      <div className="field form-group" style={{marginLeft:"20px"}}>
                                  <label>Username</label><br/>
                                  <input type="text" name="usuario" className="form-control"
                                  value={email}
                                  onChange={(e) => formInputChange("email", e.target.value)}
                                  label="Email"                                  
                                  />
                      </div>
                      <div className="field form-group" style={{marginLeft:"20px"}}>
                                  <label>Password</label><br/>
                                  <input type="password" name="password" className="form-control"
                                  value={password}
                                  onChange={(e) => { formInputChange("password", e.target.value) }}                                
                                  label="Password"
                                  /><br/>
                      </div >
                      <div className="field form-group" style={{marginLeft:"20px"}}> 
                                  <input type="checkbox"/> Remember me<br/>
                      </div>  
                      <div className="actions form-group" style={{marginLeft:"20px"}}>
                                  <Link onClick={(e) => {
                                      e.preventDefault();
                                      handleClick()
                                  }}>                                                                
                                    <button className="btn btn-primary">Log in </button>
                                  </Link>
                      </div>  
                                
                            
                            
                         <b>{loginErr}</b>        
                    </div>                           
                  </form>
                </div>
                <div style={{display:"flex", justifyContent:"center", width: "60%"}}>
                  <img src={imageUrl} alt="DHCS" height={300} width={300} /> 
                </div>
            </div>
          </div>
        </div>
    )
}

