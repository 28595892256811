import { useEffect, useState } from "react"
import { NavBar } from "../component/NavBar.jsx"
import { SideBar } from "../component/SideBar.jsx"
import { countyList } from "../service/TraineeService.js"
import { useNavigate } from "react-router"
import { useCookies } from "react-cookie"
import { ConfigCookies, ConfigRoles } from "../Config.js"

export const DashboardPage = ()=>{
    const [cookies, setCookie, removeCookie] = useCookies([ConfigCookies.USER_ROLE, ConfigCookies.TRAINEE_ID, ConfigCookies.TRAINEE_STEP]);
    const Navigate = useNavigate();
    const [counties, setCounties] = useState([])
    const fetchCounties = async ()=>{
        setCounties(await countyList())
    }

    if (cookies.traineeId){
        console.log('Admin mode');
        setCookie(ConfigCookies.TRAINEE_STEP, 0);        
        removeCookie(ConfigCookies.TRAINEE_ID);
    }

    if (!cookies.userRole || (cookies.userRole!==ConfigRoles.TRAINER && cookies.userRole!==ConfigRoles.ADMIN)){
        Navigate("../login")
    }

    useEffect(()=>{
        fetchCounties()
    }, [])

    return (
        <div className="col-xs-12 col-sm-12 col-md-11" style={{backgroundColor: "white"}}>
        <NavBar/>
        <SideBar selector={'dashboard'}/>
       
            <div className="container col-xs-12 state_table profile-list">
            <h1>CA State Overview</h1>
            <table id="dataGridId">
                <thead>
                <tr key={1}>
                    <th id="countyId" className="sticky_header">COUNTY</th>
                    <th className="sticky_header"># OF CM COORDINATORS</th>
                    <th># OF MEMBERS</th>
                    <th>UDT ABSTINENCE</th>
                    <th>INCENTIVES DELIVERED</th>
                    <th>INCENTIVES POSSIBLE</th>
                    <th>INCENTIVES PER MEMBER</th>
                </tr>
                </thead>
                <tbody>
                {counties.map((item) => (
                    <tr key={item._id}>
                    <td><a href={'../county-dashboard?county=' + item._id} style={{fontWeight: "bold", background: "none", fontSize: "12px", color: "#FAAF3B"}}>{item.name}</a></td>
                    <td className="gridDataNum">{item.numCoordinates}</td>
                    <td className="gridDataNum">{item.numMembers}</td>
                    <td className="gridDataNum">{item.udtAbstinence}</td>
                    <td className="gridDataNum">{item.incDelivered}</td>
                    <td className="gridDataNum">{item.incPossible}</td>
                    <td className="gridDataNum">{item.incPerMember}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        
            </div>
        </div>
    )
}