import { useState, useEffect, useCallback } from "react";
import { NavBar } from "../../component/NavBar.jsx"
import { SideBar } from "../../component/SideBar.jsx"
import { testCaseList, testTrack } from "../../service/TraineeService.js";
import { ConfigStatus, ConfigTestCaseStatus } from "../../Config.js";
import { dialogClose, dialogModal } from "../../component/Dialog.jsx";

const EventItem = ({ udt, handleFlag }) => {
    const styleObj = { padding: '4px 20px 4px 20px', color: udt.color };

    return (
        <td style={styleObj}><div>{udt.label} {udt.warn && (<img onClick={() => handleFlag(udt.warn)} style={{ float: 'right' }} src='assets/redflag.png' alt={udt.warn} />)} </div></td>
    )
}
const TestCaseItem = ({ testCase, currentTestCase }) => {
    let color;
    let enabled = false;
    let border = '1px solid #000000'
    let padding = '5px'
    if (testCase._id===currentTestCase){
        color = '#408000';
        border = '2px solid #000000'
        padding = '6px'
    }else{
        if (testCase.status==='completed'){
            color = '#ffffff'
            enabled = true;
        } else if (testCase.status==='approved'){
            color = '#ffd000'
            enabled = true;
        }else{
            color = '#c0c0c0';
        }
    }

    const handle = () => {
        console.log("../patient-weeks-test?testcase_id=" + testCase._id)
        if (enabled) {
            console.log("../patient-weeks-test?testcase_id=" + testCase._id)
            document.location.href = "../patient-weeks-test?testcase_id=" + testCase._id;
        }
    }
    return (<div onClick={handle} style={{ padding: padding, cursor: enabled ? 'pointer' : '', border: border, background: color }}>
        {testCase.name}<br/>{ConfigTestCaseStatus[testCase.status].label}
    </div>)
}

export const PatientWeeksTestPage = () => {
    const [providerName, setProviderName] = useState('')
    const [weekView, setWeekView] = useState(1)
    const searhParams = new URLSearchParams(document.location.search);
    const testCaseId = searhParams.get("testcase_id");
    const [testResultMap, setTestResultMap] = useState([])
    const [member, setMember] = useState({});

    let week = 1;
    const [totalReward, setTotalReward] = useState(0)
    const [totalIncentives, setTotalIncentives] = useState(0)
    const [weeksCompleted, setWeeksCompleted] = useState(0)
    const [redFlagMessage, setRedFlagMessage] = useState('')

    const [caseList, setCaseList] = useState([]);
    const [totalFixes, setTotalFixes] = useState(0)
    const [caseStatus, setCaseStatus] = useState('')

    const handleFlag = (message) => {
        setRedFlagMessage(message)
        dialogModal("flagDlgId")
    }

    const handleApprove = async () => {
        await testTrack({
            testCase: testCaseId,
            step: 'approve',
            status: 'approved',
            message: 'Approve case'
        })
        window.location.reload()
    }

    const handleResume = async () => {
        await testTrack({
            testCase: testCaseId,
            step: 'resume',
            status: 'resume',
            message: 'Request resume'
        })
        window.location.reload()
    }

    const fetchTestResult = useCallback(async () => {
        console.log('Fetch')
        let weeksCmp = 0;
        let testResult;
        await testCaseList(testCaseId).then((tc) => {
            setMember(tc.member)
            setCaseList(tc.testCaseList)
            testResult = tc.testResult;
            setCaseStatus(tc.status)
        }, [])
        setProviderName('')

        let eventMap = [];
        let i = 0;
        let w = 0;
        let fixesCmp = 0;

        for (i = 0; i < 24; i += 2) {
            eventMap[w] = { udt1: testResult.tresult[i], udt2: testResult.tresult[i + 1], udt3: testResult.tresult[w + 24], week: w + 1 }
            if (testResult.tresult[i].event !== 'pending' && testResult.tresult[i + 1].event !== 'pending') {
                weeksCmp++;
            }
            if (testResult.tresult[w + 24].event !== 'pending') {
                weeksCmp++;
            }
            if (eventMap[w].udt1.warn) {
                fixesCmp++;
            }
            if (eventMap[w].udt2.warn) {
                fixesCmp++;
            }
            w++;
        }
        setTotalReward(testResult.totalReward)
        setTotalIncentives(testResult.totalIncentives)
        setTestResultMap(eventMap)
        setWeeksCompleted(weeksCmp)
        setTotalFixes(fixesCmp)
    }, [testCaseId]);

    useEffect(() => {
        fetchTestResult();
    }, [fetchTestResult])

    return (
        <div>
            <NavBar />
            <SideBar />
            <div className="container">
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <h1>{providerName} &gt; {member.firstName} {member.lastName}</h1>
                            </td>
                            <td rowSpan={2}>
                                {caseStatus === ConfigStatus.COMPLETED && (<button onClick={handleApprove} type='button' className="greenButton" style={{ width: '180px', whiteSpace: 'nowrap' }}>Approve Case ?<br />{totalFixes} Corrections made</button>)}<br />
                                {caseStatus === ConfigStatus.COMPLETED && (<button onClick={handleResume} type='button' className="redButton" style={{ width: '180px', whiteSpace: 'nowrap' }}>Request Resubmission ?<br />{totalFixes} Corrections made</button>)}
                                {(caseStatus === ConfigStatus.APPROVED || caseStatus === ConfigStatus.RESUME) && <div>Evaluation done</div>}
                            </td>
                            <td rowSpan={3}>
                                <table style={{ width: '100%', margin: '0px' }}>
                                    <tbody>
                                        <tr><td style={{ padding: '10px' }}><h3>Reward Bank<br />
                                            $ {Number(totalReward).toFixed(2)}</h3>
                                            <img src="assets/dhcs-gift-card.png" alt="Rewards" /><br />
                                            {totalReward === 0 ? (<span style={{ cursor: 'pointer' }}>You have no money to redeem.</span>) : (<span style={{ cursor: 'pointer' }}>Redeem now!</span>)}

                                        </td><td>
                                                <div style={{ border: '4px solid #ff0000', textAlign: 'center', padding: '10px' }}>
                                                    Reconfirm CM Eligibility in:<br />0 days:
                                                    <div style={{ border: '1px solid #000000', padding: '5px' }}>Confirm</div>
                                                </div>
                                            </td></tr>
                                        <tr><td style={{ padding: '20px' }} colSpan={2}>
                                            <div style={{ textAlign: 'center' }}>
                                                <input type="radio" defaultChecked={weekView === 1} /> Incentive Summary
                                                <input type="radio" defaultChecked={weekView === 2} /> Incentive History
                                            </div>
                                        </td></tr>
                                        <tr><td style={{ padding: '20px' }} colSpan={2}>
                                            <div style={{ height: '300px', textAlign: 'center', border: '1px solid #c0c0c0' }}>
                                                <p style={{ font: 'bold 40px Arial', color: '#404040' }}>Weeks Completed: {weeksCompleted} of 24</p>
                                                <p style={{ font: '40px Arial', color: '#404040' }}>Total Incentives: ${Number(totalIncentives).toFixed(2)}</p>
                                            </div>
                                        </td></tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: 'top' }}>
                                <div style={{ textAlign: "left", width: "50%" }}>
                                    <b>Practice Case</b><br />
                                </div>
                                <div style={{ float: 'left', width: "50%" }}>
                                    <div className="pagination_groups text-center udt-radio-button" style={{ width: "100%" }}>
                                        <input type="radio" defaultChecked={weekView === 1} onClick={() => setWeekView(1)} /> Weeks 1-12
                                        <input type="radio" disabled={true} checked={weekView === 2} onClick={() => setWeekView(2)} /> Weeks 13-24
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ padding: "0px", verticalAlign: 'top' }}>
                                {
                                    caseList.map((item) => (
                                        <TestCaseItem testCase={item} key={item._id} currentTestCase={testCaseId}/>
                                    ))
                                }
                            </td>
                            <td colSpan={2} style={{ padding: "0px", verticalAlign: 'top' }}>

                                <div className="week_table">
                                    <table style={{ borderCollapse: 'collapse', visible: weekView === 1, 'display': weekView === 2 ? 'none' : '' }}>
                                        <tbody>
                                            <tr><th>Week</th><th>UDT 1</th><th>UDT 2</th><th>$ Earned</th></tr>
                                            {
                                                testResultMap.map((item) => (
                                                    <tr key={item.week} style={(week - 1) === weeksCompleted ? { border: '4px solid #ff8040', outline: 'thin line' } : {}}>
                                                        <td>{week++}</td>
                                                        <EventItem udt={item.udt1} handleFlag={handleFlag} />
                                                        <EventItem udt={item.udt2} handleFlag={handleFlag} />
                                                        <td>{Number(Number(item.udt1.amount) + Number(item.udt2.amount)).toFixed(2)}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                                <table style={{ visible: weekView === 2, 'borderCollapse': 'collapse', 'border': '2px solid #000000', 'background': '#f0f0f0', 'display': weekView === 1 ? 'none' : '' }}>
                                    <tbody>
                                        <tr><th>Week</th><th>UDT 1</th><th>$ Earned</th></tr>
                                        {
                                            testResultMap.map((item) => (
                                                <tr key={item.week}>
                                                    <td>{week++}</td>
                                                    <EventItem udt={item.udt3} handleFlag={handleFlag} />
                                                    <td>{item.udt3.amount}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                                <div className="greenButton">Test Next Week</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <dialog id="flagDlgId" style={{ cursor: 'pointer' }} onClick={() => dialogClose("flagDlgId")}>
                <img style={{ float: 'left' }} src='assets/redflag.png' alt={redFlagMessage} /> {redFlagMessage}
            </dialog>
        </div>
    )
};