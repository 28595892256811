import { createContext, useContext} from 'react';
import { useCookies } from 'react-cookie';
import { ConfigCookies } from '../Config';
import { testTrack } from '../service/TraineeService';
import { showWrongTip } from '../component/WrongTip';

export const AppContext = createContext();

export const useAppContext = ()=>{
    const context = useContext(AppContext);
    return context;
}

export const AppProvider =({children})=>{
    const [cookies, setCookie] = useCookies([ConfigCookies.USER_ROLE,ConfigCookies.USER_AUTHENTICATED, ConfigCookies.TRAINEE_ID, ConfigCookies.PRACTICE_CASE_ID, ConfigCookies.PRACTICE_CASE_STEPS, ConfigCookies.TRAINEE_STEP]);
    
    const isAuthenticated = () =>{
        const token = localStorage.getItem('token');
        if (token !== null) {
            return true;
        }
        return false;
    }

    const isAuthorized = (roles)=>{
        let authorized = false;
        roles.forEach((role)=>{
            if (role===cookies.userRole){
                authorized = true
            }
        })
        return authorized
    }

    const checkStep =  (inputStep) => {
        if (!cookies.traineeId){
            return true;
        }
        let step = cookies.traineeStep;
        const steps = cookies.practiceCaseSteps
        console.log('STEP', step)
        if (step>=steps.length){
            return true;
        }
        // if (inputStep===steps[step]){
        const foundStep = steps[step].split('|').includes(inputStep);
        console.log('Found',inputStep,foundStep)
        if (foundStep){
            let trackStatus = 'ok';
            step++;
            cookies.traineeStep++;
            setCookie(ConfigCookies.TRAINEE_STEP, step)
            if (step === (steps.length)){
                trackStatus = 'finished'
            }else if (step===1){
                trackStatus = 'started'
            }
            testTrack({
                testCase:cookies.practiceCaseId,
                step:inputStep,
                status:trackStatus,
                message:'Success'
            })
             
            return true;
        }else{
            const message = 'Received status '+inputStep+' Expected status ' + steps[step] + ' step '+ step;
            console.log('FAIL', message);
            testTrack({
                testCase:cookies.practiceCaseId,
                step:inputStep,
                status:'fail',
                message
            })
            showWrongTip('Wrong step!');
            console.log('Wrong')
            return false;
        }
    }

    const checkFinish = (handleFinish)=>{
        const step = cookies.traineeStep;
        const steps = cookies.practiceCaseSteps
        console.log('STEP::', step)
        if (step === steps.length){
            handleFinish();
        }
    }

    return (
        <AppContext.Provider value={{
            checkStep, isAuthenticated, checkFinish, isAuthorized
        }}>
            {children}
        </AppContext.Provider>
    )
}
