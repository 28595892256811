import { useCallback, useEffect, useState } from "react";
import { NavBar } from "../component/NavBar.jsx"
import { SideBar } from "../component/SideBar.jsx"

import { providerList } from "../service/TraineeService.js";

export const CountyDashboardPage = ()=>{
    const searhParams = new URLSearchParams(document.location.search);
    const county = searhParams.get("county");

    const [providers, setProviders] = useState([])

    const fetchProviders = useCallback(async()=>{
        setProviders(await providerList(county));
        console.log('fetched')
    },[county]);

    useEffect(()=>{
        fetchProviders();
    }, [fetchProviders])

    return (
        <div>
            <NavBar/>
            <SideBar selector="county-analytics"/>
            <div className="container col-xs-12 state_table profile-list">
                <div>
                    <div style={{'float':'left'}}>
                        <h1>County Name</h1>
                    </div>
                    <div style={{'float':'right'}}>
                        
                        <div style={{'float':'left'}}>
                            Start Date<br/>
                            <input type="date"/>
                        </div>
                        <div style={{'float':'left'}}>
                            End Date<br/>
                            <input type="date"/>
                        </div>
                        <div style={{'float':'left'}}>
                            Export<br/>
                            <select>
                                <option value="*"></option>
                                <option value="CSV">CSV</option>
                                <option value="CSV">PDF</option>
                            </select>
                        </div>
                    </div>
                </div>
                <table className="gridData">
                    <thead>
                    <tr>
                        <th>Provider Organization</th><th># OF CM COORDINATORS</th><th># OF MEMBERS</th><th>INCENTIVES DELIVERED</th>
                        <th>UDT ABSTINENCE</th><th>UDT Total</th><th>UDT Pos</th><th>UDT Neg</th><th>UDT Absences</th>
                    </tr>
                    </thead>
                    <tbody>
                    {providers.map((item) => (
                        <tr key={item._id}>
                        <td><a href={'../pro-home-pa?practice_id=' + item._id}>{item.name}</a></td>
                        <td className="gridDataNum">0</td>
                        <td className="gridDataNum">0</td>
                        <td className="gridDataNum">0</td>                        
                        <td className="gridDataNum">0</td>
                        <td className="gridDataNum">0</td>
                        <td className="gridDataNum">0</td>
                        <td className="gridDataNum">0</td>
                        <td className="gridDataNum">0</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        
        </div>
    )
};