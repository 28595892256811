import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import userpool from '../service/userpool';

export const authenticate=(Email,Password)=>{
    return new Promise((resolve,reject)=>{
        const user = new CognitoUser({
            Username:Email,
            Pool: userpool
        });

        const authDetails= new AuthenticationDetails({
            Username:Email,
            Password
        });

        user.authenticateUser(authDetails,{
            onSuccess:(result)=>{
                const token = JSON.stringify(result.idToken.jwtToken);
                console.log("login successful data", token);                
                localStorage.setItem('token', token);
                localStorage.setItem('email', Email);
                resolve(result);
            },
            onFailure:(err)=>{
                console.log("login failed",err);
                reject(err);
            }
        });
    });
};

export const logout = () => {
    const user = userpool.getCurrentUser();
    localStorage.removeItem('token');
    user.signOut();
};

  
