
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import { LoginPage } from './pages/LoginPages'
import { IndexPage } from './pages/IndexPage';
import { DashboardPage } from './pages/DashboardPage';
import { StateAnalyticsPage } from './pages/StateAnalyticsPage';
import { CountyAnalyticsPage } from './pages/CountyAnalyticsPage';
import { ProviderAnalyticsPage } from './pages/ProviderAnalyticsPage';
import { StateReportPage } from './pages/StateReportPage';
import { ProviderReportPage } from './pages/ProviderReportPage';
import { AdminManagerPage } from './pages/AdminManagerPage'
import { CompaniesPage } from './pages/CompaniesPage';
import { EventsPage } from './pages/EventsPage';
import { PracticesPage } from './pages/PracticesPage';
import { OarsAdminRolesPage } from './pages/OarsAdminRolesPage';
import { HelpPage } from './pages/HelpPage';
import { LogoutPage } from './pages/LogoutPage';
import { CountyDashboardPage } from './pages/CountyDashboardPage';
import { ProviderHomePaPage } from './pages/member/ProviderHomePaPage';
import { PatientWeeksPage } from './pages/member/PatientWeeksPage';
import { PatientWeeksTestPage } from './pages/member/PatientWeeksTestPage';
import { TestPage } from './pages/TestPage';
import { TrainiListPage } from './pages/traini/TrainiListPage';

import { CookiesProvider } from 'react-cookie';
import { AppProvider } from './context/AppContext';
import { TrainerListPage } from './pages/traini/TrainerListPage';


const PrivateRoute = ({children}) => { 
  if (!isAuthenticated()) {
    return <Navigate to="/login" />
  }
  return children

};


const isAuthenticated = () => {
  const token = localStorage.getItem('token');
    if (token !== null) {
        return true;
    }
    return false;
};

function App() {
  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <AppProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<IndexPage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/dashboard' element={<PrivateRoute><DashboardPage /></PrivateRoute>} />
          <Route path='/state-analytics' element={<PrivateRoute><StateAnalyticsPage /></PrivateRoute>} />
          <Route path='/county-analytics' element={<PrivateRoute><CountyAnalyticsPage /></PrivateRoute>} />          
          <Route path="/county-dashboard" element= {<PrivateRoute ><CountyDashboardPage /></PrivateRoute> } />
          <Route path='/provider-analytics' element={<PrivateRoute><ProviderAnalyticsPage /></PrivateRoute>} />
          <Route path='/state-report' element={<PrivateRoute><StateReportPage /></PrivateRoute>} />
          <Route path='/provider-report' element={<PrivateRoute><ProviderReportPage /></PrivateRoute>} />
          <Route path='/users' element={<PrivateRoute><TrainerListPage /></PrivateRoute>} />
          <Route path='/admin' element={<PrivateRoute><AdminManagerPage /></PrivateRoute>} />
          <Route path='/companies' element={<PrivateRoute><CompaniesPage /></PrivateRoute>} />
          <Route path='/events' element={<PrivateRoute><EventsPage /></PrivateRoute>} />
          <Route path='/practices' element={<PrivateRoute><PracticesPage /></PrivateRoute>} />
          <Route path='/oarsadminroles' element={<PrivateRoute><OarsAdminRolesPage /></PrivateRoute>} />
          <Route path='/help' element={<PrivateRoute><HelpPage /></PrivateRoute>} />
          <Route path='/logout' element={<PrivateRoute><LogoutPage /></PrivateRoute>} />
          <Route path='/pro-home-pa' element={<PrivateRoute><ProviderHomePaPage /></PrivateRoute>} />
          <Route path='/patient-weeks' element={<PrivateRoute><PatientWeeksPage /></PrivateRoute>} />
          <Route path='/patient-weeks-test' element={<PrivateRoute><PatientWeeksTestPage /></PrivateRoute>} />
          <Route path='/trainer' element={<PrivateRoute><TrainiListPage /></PrivateRoute>} />
          <Route path='/testcase' element={<PrivateRoute><TestPage /></PrivateRoute>} />
        </Routes>
      </BrowserRouter>
      </AppProvider>
    </CookiesProvider>
  );
}

export default App;
