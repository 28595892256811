import { useState, useEffect, useCallback } from "react";
import { NavBar } from "../../component/NavBar.jsx"
import { SideBar } from "../../component/SideBar.jsx"
import { memberGet, testResultList, upadateTestResult, warnTestResult } from "../../service/TraineeService.js";
import { ImgButton } from "../../component/ImgButton.jsx";
import { WrongTip } from "../../component/WrongTip.jsx";
import { dialogModal, dialogClose } from "../../component/Dialog.jsx";
import { useAppContext } from "../../context/AppContext.jsx";
import { ConfigCookies, ConfigSteps } from "../../Config.js";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../component/Spinner.jsx";

const GiftCard = {
    '01': 'Dominos',
    '02': 'Nike',
    '03': 'Wallmart',
    '04': 'GAP',
    '05': 'Whopper'
}

const EventButton = ({ handler, selEvent, event, color, label }) => {
    let style = (selEvent === event) ? { 'border': '1px solid #202020', background: '#808080', 'cursor': 'pointer', 'color': 'white' } : { 'border': '1px solid #202020', 'cursor': 'pointer', 'color': color };

    return (<div onClick={() => { handler(event) }} style={style}>{label}</div>);
}

let eligibility = false;

const EventItem = ({ udt, handleClikWeek, updateUdt }) => {

    let styleObj;
    let handle;
    let styleEnabled = {}
    if (udt.enabled) {
        handle = () => handleClikWeek(udt._id, updateUdt);
        styleObj = { padding: '4px 20px 4px 20px', color: udt.color, cursor: 'pointer' };
        styleEnabled = {border:'1px solid #808080',background:'#ffffff'}
    } else {
        handle = () => { }
        styleObj = { padding: '4px 20px 4px 20px', color: udt.color };
    }

    return (
        <td style={styleObj} onClick={handle}><div style={styleEnabled}>{udt.label}</div></td>
    )
}

export const PatientWeeksPage = () => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [cookies] = useCookies(ConfigCookies.TRAINEE_ID)
    const [linkCard, setLinkCard] = useState('');
    const [providerName, setProviderName] = useState('')
    const { checkStep, checkFinish } = useAppContext();
    const [weekView, setWeekView] = useState(1)
    const searhParams = new URLSearchParams(document.location.search);
    const memberId = searhParams.get("user_id");
    const [testResultMap, setTestResultMap] = useState([])
    const [member, setMember] = useState({});

    const [abscentComment, setAbscentComment] = useState('')
    const [lastAbscentComment, setLastAbscentComment] = useState('')
    const [lastEventAbscence, setLastEventAbscence] = useState('')
    const [selectedEventId, setSelectedEventId] = useState('');

    let week = 1;
    const [updateUdtAmount, setUpdateUdtAmount] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState('');
    const [selectedGifcard, setSelectedGifcard]  = useState(null);

    const [gifcardMethodSelected, setGifcardMethodSelected] = useState('')

    const [memberEmail, setMemberEmail] = useState(false)
    const [memberCell, setMemberCell] = useState(false)
    const [totalReward, setTotalReward] = useState(0)
    const [totalIncentives, setTotalIncentives] = useState(0)
    const [weeksCompleted, setWeeksCompleted] = useState(0)
    const [givenUdt, setGivenUdt] = useState(0)

    const [requireConfimation, setRequireConfimation] = useState(false)
    
    console.log('Loading')
    const fetchMember = useCallback(async () => {
        setMember(await memberGet(memberId))
    },[memberId]);

    const fetchTestResult = useCallback(async () => {
        let weeksCmp = 0;
        let testResult = await testResultList(memberId);
        let eventMap = [];
        let i = 0;
        let w = 0;

        setLastAbscentComment('');
        setLastEventAbscence(null);
        for (i = 1; i < 36; i++) {
            if (testResult.tresult[i].event === 'pending' && testResult.tresult[i - 1].event === 'abscence' && testResult.tresult[i - 1].confirm) {
                setLastAbscentComment(testResult.tresult[i - 1].comment);
                console.log(testResult.tresult[i - 1])
                setLastEventAbscence(testResult.tresult[i - 1]._id)
                console.log('Confirm Abscence!', i)
                break;
            }
        }

        let completedUdt = 0;
        for(i=0;i<testResult.tresult.length;i++){
            if (testResult.tresult[i].event!=='pending'){
                completedUdt++;
            }
        }
        if (completedUdt<8){
            eligibility = false;
        }
        setRequireConfimation(completedUdt===8 && !eligibility);    // is the 5 week

        for (i = 0; i < 24; i += 2) {
            eventMap[w] = { udt1: testResult.tresult[i], udt2: testResult.tresult[i + 1], udt3: testResult.tresult[w + 24], week: w + 1 }
            if (testResult.tresult[i].event !== 'pending' && testResult.tresult[i + 1].event !== 'pending') {
                weeksCmp++;
            }
            if (testResult.tresult[w + 24].event !== 'pending') {
                weeksCmp++;
            }
            w++;
        }
        setTotalReward(testResult.totalReward)
        setTotalIncentives(testResult.totalIncentives)
        setTestResultMap(eventMap)
        setWeeksCompleted(weeksCmp)
    },[memberId]);

    useEffect(() => {
        fetchMember();
        fetchTestResult();
    }, [fetchMember, fetchTestResult])

    useEffect(() => {
        if (member._id) {
            setMemberCell(member.phoneNumber && member.phoneNumber !== '');
            setMemberEmail(member.email && member.email !== '')
            setProviderName(member.practice.name)
        }
    }, [member])

    const handleTestButtonClick = () => {
        if (checkStep(ConfigSteps.TEST_NEXT_WEEK)) {
            dialogModal('testModalDialog')
        }
        checkFinish(handleFinish)
    };

    const handleTestCloseModal = () => {
        dialogClose('testModalDialog')
    };

    const handleFinish = ()=>{
        navigate("../testcase?trainee_id="+cookies.traineeId)
    }

    const handleClikWeek = (event, updateUdt) => {
        /**
         * Moved validation to dont interrupt the step tracking
         */
        console.log('requireConfimation 2',requireConfimation,'eligibitily', eligibility)
        if (requireConfimation){
            dialogModal('actionAlertDialog');
            return;
        }
        if (checkStep(ConfigSteps.SELECT_EVENT)) {
            setSelectedEventId(event);
            setUpdateUdtAmount(updateUdt)
            setSelectedEvent('');

            if (lastEventAbscence) {
                setAbscentComment('');
                dialogModal('confirmAbscenceDialog')
            } else {
                dialogModal('eventDialogId')
            }
        }
        checkFinish(handleFinish)
    }

    const handleSelectEventCloseModal = () => {
        dialogClose('eventDialogId')
    }

    const handleSelectEventModal = (selected) => {
        if (checkStep(selected + 'Event')) {
            setSelectedEvent(selected)
            console.log(selectedEvent)
        }else{
            warnTestResult(selectedEventId, 'Wrong event selected');
        }
        console.log('check finish')
        checkFinish(handleFinish)
    }

    const handleWeekEventSubmitModal = async () => {
        if (checkStep(ConfigSteps.SUBMIT_EVENT)) {
            dialogClose('eventDialogId')
            if (selectedEvent === 'abscence') {
                setAbscentComment('');
                dialogModal('abscenceModalDialog')
            } else {
                // negative, positive, unexcused
                dialogModal('eventWeekModalId')
            }
        }
        checkFinish(handleFinish)
    }

    const handleWeekEventCloseModal = () => {
        dialogClose('eventWeekModalId')
    };

    const handleConfirm = async (response) => {
        if (checkStep(response + 'ConfirmEvent')) {
            dialogClose('eventWeekModalId')
            if (response === 'yes') {
                setLoading(true)
                const responseUpdate = await upadateTestResult({ id: selectedEventId, event: selectedEvent, updateUdt: updateUdtAmount })
                setLoading(false)
                setGivenUdt(responseUpdate.givenUdt);
                // Original Fetch Test Result
                // await fetchTestResult()
                if (selectedEvent === 'negative') {
                    console.log(responseUpdate)
                    dialogModal('gifcardModalDialog')
                }else{
                    await fetchTestResult();
                }
            } else {
                setSelectedEvent('')
                dialogModal('eventDialogId')
            }
        }
        checkFinish(handleFinish)
    };

    const handleAbscenceSubmitModal = async () => {
        if (checkStep(ConfigSteps.ABSCENCE_SUBMIT)) {
            setLoading(true)
            dialogClose('abscenceModalDialog')
            await upadateTestResult({ id: selectedEventId, event: selectedEvent, comment: abscentComment })
            await fetchTestResult()
            setLoading(false)
            
        }
        checkFinish(handleFinish)
    }

    const handleSelectGifcard = (gifcard) => {
        if (checkStep(gifcard + 'GIFTCARD')) {
            setSelectedGifcard(gifcard);
            dialogClose('gifcardModalDialog')
            dialogModal('gitcardConfirmDialog')
        }else{
            if (selectedEventId){
                warnTestResult(selectedEventId, 'Wrong gift card selected');
            }
        }
        checkFinish(handleFinish)
    }

    const handleGiftcardCloseModal = async () => {
        if (checkStep(ConfigSteps.CLOSE_GIFT_SELECT)) {
            await fetchTestResult();
            dialogClose('gifcardModalDialog')
        }else{
            if (selectedEventId){
                warnTestResult(selectedEventId, 'Wrong cash-ut or bank selected');
            }
        }
        checkFinish(handleFinish)
    }

    const handleGifcardConfirm = async (response) => {
        if (checkStep(response + 'GiftConfirm')) {
            dialogClose('gitcardConfirmDialog')
            if (response === 'no') {
                dialogModal('gifcardModalDialog')
            } else {
                setGifcardMethodSelected('print')
                dialogModal('gitcardMethodDialog')
            }
        }
        checkFinish(handleFinish)
    }

    const handleGifcardMethod = async () => {
        console.log('method ', gifcardMethodSelected)

        let method = '';
        if (gifcardMethodSelected==='email'){
            method = 'EMAIL'
        }else if (gifcardMethodSelected==='cell'){
            method = 'PHONE'
        }else {
            method = 'LINK'
        }

        if (checkStep(gifcardMethodSelected + ConfigSteps.CONFIRM_GIFT_METHOD)) {
            dialogClose('gitcardMethodDialog')
            setLoading(true)
            if (selectedEventId){
                // Gift card associated to event
                const response = await upadateTestResult({ id: selectedEventId, award: givenUdt, card:{
                    reward:givenUdt,
                    name:member.firstName + ' ' + member.lastName,
                    email:member.email,
                    phone:member.phoneNumber,
                    method:method,
                    product:'SERJ8PTGGXLL',
                } })                
                setLinkCard(response.link)                
            }else{
                // Gift card associated to the member
                const response = await upadateTestResult({ memberId:memberId, award: totalReward, card:{
                    reward:totalReward,
                    name:member.firstName + ' ' + member.lastName,
                    email:member.email,
                    phone:member.phoneNumber,
                    method:method,
                    product:'SERJ8PTGGXLL',
                } })
                console.log('Card response', response);
                setLinkCard(response.link)
            }
            await fetchTestResult()
            setLoading(false)
            dialogModal('giftcardSendDialog');
        }else{
            if (selectedEventId){
                warnTestResult(selectedEventId, 'Wrong gift card delivery selected');
            }
        }
        checkFinish(handleFinish)
    }

    const handleGifcardConfirmClose = async () => {
        if (checkStep(ConfigSteps.CLOSE_GIFT_CONFIRM)) {
            dialogClose('gitcardConfirmDialog')
        }
    }

    const handleGifcardMethodClose = async () => {
        if (checkStep(ConfigSteps.CLOSE_GIFT_METHOD)) {
            // Update UDT matrix, without send giftcard
            await fetchTestResult();
            dialogClose('gitcardMethodDialog')
        }
        checkFinish(handleFinish)
    }

    const handleGifcardSendClose = async () => {
        if (checkStep(ConfigSteps.CLOSE_GIFT_SEND)) {
            dialogClose('giftcardSendDialog');
        }
        checkFinish(handleFinish)
    }

    const handleGifcardPrintNow = async () => {
        if (checkStep(ConfigSteps.PRINT_NOW)) {
            window.open(linkCard);
        }
        checkFinish(handleFinish)
    }

    const handleConfirmAbscence = async (action) => {
        if (checkStep(action + 'ConfirmAbscence')) {
            dialogClose('confirmAbscenceDialog');
            setLoading(true)
            const eventSend = (action === 'excused') ? 'abscence' : 'unexcused';
            await upadateTestResult({ id: lastEventAbscence, event: eventSend, comment: lastAbscentComment })
            await fetchTestResult()
            setLoading(false)
        }
        checkFinish(handleFinish)
    }

    const handleRedeem = async ()=>{
        if (checkStep(ConfigSteps.REDEEM)){
            setSelectedEventId(null);
            dialogModal('gifcardModalDialog');
        }
        checkFinish(handleFinish)
    }

    const handleActionAlertClose = ()=>{
        dialogClose('actionAlertDialog');
    }

    const handleConfirmSet = ()=>{
        eligibility = true;
        setRequireConfimation(false);
    }
    return (
        <div>
            <NavBar />
            <SideBar />
            <div className="container">                
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td>
                                <h1>{providerName} &gt; {member.firstName} {member.lastName}</h1>
                                <div style={{textAlign: "center"}}>
                                    <b>Current Week = {weekView}</b><br />
                                </div>
                                <div className="row">    
                                    <div className="pagination_groups text-center udt-radio-button" style={{width: "100%"}}>
                                        <input type="radio" defaultChecked={weekView === 1} onClick={() => setWeekView(1)} /> Weeks 1-12                                
                                        <input type="radio" disabled={true} checked={weekView === 2} onClick={() => setWeekView(2)} /> Weeks 13-24
                                    </div>    
                                </div>
                                <div className="week_table">
                                    <table style={{borderCollapse:'collapse', visible: weekView === 1, 'display': weekView === 2 ? 'none' : '' }}>
                                        <tbody>
                                            <tr><th>Week</th><th>UDT 1</th><th>UDT 2</th><th>$ Earned</th></tr>
                                            {
                                                testResultMap.map((item) => (                                                    
                                                    <tr key={item.week} style={(week-1)===weeksCompleted?{border:'4px solid #ff8040',outline:'thin line'}:{}}>
                                                        <td>{week++}</td>
                                                        <EventItem udt={item.udt1} handleClikWeek={handleClikWeek} updateUdt={false} />
                                                        <EventItem udt={item.udt2} handleClikWeek={handleClikWeek} updateUdt={true} />
                                                        <td>{Number(Number(item.udt1.amount) + Number(item.udt2.amount)).toFixed(2)}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                               </div>     
                                <table style={{ visible: weekView === 2, 'borderCollapse': 'collapse', 'border': '2px solid #000000', 'background': '#f0f0f0', 'display': weekView === 1 ? 'none' : '' }}>
                                    <tbody>
                                        <tr><th>Week</th><th>UDT 1</th><th>$ Earned</th></tr>
                                        {
                                            testResultMap.map((item) => (
                                                <tr key={item.week}>
                                                    <td>{week++}</td>
                                                    <EventItem udt={item.udt3} handleClikWeek={handleClikWeek} updateUdt={true} />
                                                    <td>{item.udt3.amount}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                                <div onClick={handleTestButtonClick} className="greenButton">Test Next Week</div>
                            </td>
                            <td>
                                <table style={{ width: '100%' }}>
                                    <tbody>
                                        <tr><td style={{ padding: '20px' }}><h3>Reward Bank<br />
                                            $ {Number(totalReward).toFixed(2)}</h3>
                                            <img src="assets/dhcs-gift-card.png" alt="Rewards" /><br />                                            
                                            {totalReward===0?(<span style={{cursor:'pointer'}}>You have no money to redeem.</span>):(<span onClick={handleRedeem} style={{cursor:'pointer'}}>Redeem now!</span>)}
                                            
                                        </td><td>
                                            {requireConfimation && (
                                            <div style={{border:'4px solid #ff0000',textAlign:'center',padding:'10px'}}>
                                                Reconfirm CM Eligibility in:<br />0 days:
                                                <div onClick={handleConfirmSet} style={{border:'1px solid #000000', padding:'5px'}}>Confirm</div>
                                            </div>)}
                                            {!requireConfimation && (
                                            <div style={{border:'1px solid #f0f0f0',textAlign:'center',padding:'10px'}}>
                                                Reconfirm CM Eligibility in:<br />27 days:
                                                <div>Confirm</div>
                                            </div>)}
                                            </td></tr>
                                        <tr><td style={{ padding: '20px' }} colSpan={2}>
                                            <div style={{ textAlign: 'center' }}>
                                                <input type="radio" defaultChecked={weekView === 1} /> Incentive Summary
                                                <input type="radio" defaultChecked={weekView === 2} /> Incentive History
                                            </div>
                                        </td></tr>
                                        <tr><td style={{ padding: '20px' }} colSpan={2}>
                                            <div style={{ height: '300px', textAlign: 'center', border: '1px solid #c0c0c0' }}>
                                                <p style={{ font: 'bold 40px Arial', color: '#404040' }}>Weeks Completed: {weeksCompleted} of 24</p>
                                                <p style={{ font: '40px Arial', color: '#404040' }}>Total Incentives: ${Number(totalIncentives).toFixed(2)}</p>
                                            </div>
                                        </td></tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <dialog id="testModalDialog" onClose={handleTestCloseModal}>
                Test Next Week?
                <div onClick={handleTestCloseModal} className="greenButton">Execute</div>
            </dialog>
            <dialog onClose={handleSelectEventCloseModal} id="eventDialogId">
                <EventButton handler={handleSelectEventModal} selEvent={selectedEvent} event="negative" color="green" label="Stimulant -Neg ($)" />
                <EventButton handler={handleSelectEventModal} selEvent={selectedEvent} event="positive" color="red" label="Stimulant +Pos" />
                <EventButton handler={handleSelectEventModal} selEvent={selectedEvent} event="abscence" color="grey" label="Excuse Abscence(Note)" />
                <EventButton handler={handleSelectEventModal} selEvent={selectedEvent} event="unexcused" color="brown" label="Unexcused Absence" />
                <button disabled={selectedEvent === ''} onClick={handleWeekEventSubmitModal} className="greenButton">Submit</button>
            </dialog>
            <dialog id="confirmAbscenceDialog">
                <h1>Confirm Previous Excused Abscence</h1>
                Before continuing, you must confirm the member's<br /> previous excused absence.<br />
                Please verify and update the note below. If this entry<br /> was incorrect, you can select &quot;Unexcused&quot;<br />
                If you select &quot;Unexcused&quot; the prior result will be changed to<br /> &quot;Unexcused Absence&quot; and the next Neg UDT entered will be for $10<br />
                <textarea style={{ 'width': '100%' }} value={lastAbscentComment} disabled={true}>

                </textarea>
                <textarea style={{ 'width': '100%' }} value={abscentComment} onChange={(e) => setAbscentComment(e.target.value)}>
                    {abscentComment}
                </textarea>
                <div>
                    <button type="button" onClick={() => { handleConfirmAbscence('excused') }} className="greenButton" style={{ 'float': 'left' }}>Excused</button>
                    <button type="button" onClick={() => { handleConfirmAbscence('unexcused') }} className="redButton" style={{ 'float': "right" }}>Unexcused</button>
                </div>
            </dialog>
            <dialog onClose={(handleWeekEventCloseModal)} id="eventWeekModalId">
                <div style={{ padding: '10px' }}>
                    <h1>You have selected</h1>

                    {(selectedEvent === 'negative') && (
                        <div style={{ color: 'green', textAlign: 'center' }}>Stimulant -Neg ($)</div>
                    )}
                    {(selectedEvent === 'positive') && (
                        <div style={{ color: 'red', textAlign: 'center' }}>Stimulant -Pos ($)</div>
                    )}
                    {(selectedEvent === 'unexcused') && (
                        <div style={{ color: 'brown', textAlign: 'center' }}>Unexcused Absence</div>
                    )}
                    Are you sure? This action can not be undone.<br />
                </div>
                <div>
                    <button type="button" onClick={() => { handleConfirm('no') }} className="redButton" style={{ 'float': 'left' }}>No</button>
                    <button type="button" onClick={() => { handleConfirm('yes') }} className="greenButton" style={{ 'float': "right" }}>Yes</button>
                </div>
            </dialog>

            <dialog id="abscenceModalDialog" style={{ 'width': '300px', 'padding': '10px', 'textAlign': 'center' }}>
                You have selected Excused Absence.<br />
                You must enter a note before you can update this record.<br />
                <b>Excused Absence</b><br />
                Incentive escalation will not reset. If you did not mean to select this option, please close out of this window and choose the correct entry<br />
                <textarea placeholder="Click to enter text" style={{ 'width': '100%' }} value={abscentComment} onChange={(e) => setAbscentComment(e.target.value)}>

                </textarea>
                <button type="button" onClick={handleAbscenceSubmitModal} className="greenButton">Submit</button>
            </dialog>
            <dialog id="gifcardModalDialog">
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>Bank Incentives for Later by Closing out</td>
                            <td><img src="assets/close.png" onClick={handleGiftcardCloseModal} alt="Close" /></td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ padding: '10px', textAlign: 'center' }}>Vendor Card Selection<br />Incentive Amount: {selectedEventId?givenUdt:totalReward}</div>
                <table>
                    <tbody>
                        <tr>
                            <td><ImgButton handleClick={() => handleSelectGifcard('01')} alt="Gifcard 01" asset="gifcard01.png" /></td>
                            <td><ImgButton handleClick={() => handleSelectGifcard('02')} alt="Gifcard 02" asset="gifcard02.png" /></td>
                        </tr>
                        <tr>
                            <td><ImgButton handleClick={() => handleSelectGifcard('03')} alt="Gifcard 03" asset="gifcard03.png" /></td>
                            <td><ImgButton handleClick={() => handleSelectGifcard('04')} alt="Gifcard 04" asset="gifcard04.png" /></td>
                        </tr>
                        <tr>
                            <td><ImgButton handleClick={() => handleSelectGifcard('05')} alt="Gifcard 05" asset="gifcard05.png" /></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <input type="radio" checked={true}/>
            </dialog>

            <dialog id="gitcardConfirmDialog" style={{ textAlign: 'center' }}>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>Bank Incentives for Later by Closing out</td>
                            <td><img src="assets/close.png" onClick={handleGifcardConfirmClose} alt="Close" /></td>
                        </tr>
                    </tbody>
                </table>
                <h1>Incentive Selected</h1>
                <img src={"assets/gifcard" + selectedGifcard + ".png"} alt="Gifcard" />
                <div style={{ padding: '10px', textAlign: 'center' }}>

                    You have choosen ${Number(selectedEventId?givenUdt:totalReward).toFixed(2)} {GiftCard[selectedGifcard]}'s<br />
                    This card may not used to purchase alcohol, tobacco, firearms, or ammunition<br />
                    Is this correct?<br />
                    (Incentives can be changed once confirmed)
                </div>
                <div>
                    <div onClick={() => { handleGifcardConfirm('yes') }} className="greenButton" style={{ 'float': "left" }}>Yes</div>
                    <div onClick={() => { handleGifcardConfirm('no') }} className="redButton" style={{ 'float': 'right' }}>No</div>
                </div>
            </dialog>

            <dialog id="gitcardMethodDialog">
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>Bank Incentives for Later by Closing out</td>
                            <td><img src="assets/close.png" onClick={handleGifcardMethodClose} alt="Close" /></td>
                        </tr>
                    </tbody>
                </table>
                <h1>Choose Delivery Method</h1>
                <img src={"assets/gifcard" + selectedGifcard + ".png"} alt="Gifcard" />
                <div style={{ padding: '10px', textAlign: 'center' }}>

                    How would you like to receive your ${Number(selectedEventId?givenUdt:totalReward).toFixed(2)} {GiftCard[selectedGifcard]} gift card!<br />
                    <div style={{ display: memberCell ? '' : 'none' }}>
                        <input type="radio" name="gifcardMethodRadio" checked={gifcardMethodSelected === 'cell'} onChange={(e) => { if (e.target.checked) { setGifcardMethodSelected('cell') } }} /> Text it to me ({member.phoneNumber})<br />
                    </div>
                    <div style={{ display: memberEmail ? '' : 'none' }}>
                        <input type="radio" name="gifcardMethodRadio" checked={gifcardMethodSelected === 'email'} onChange={(e) => { if (e.target.checked) { setGifcardMethodSelected('email') } }} /> Email it to me ({member.email})<br />
                    </div>
                    <input type="radio" name="gifcardMethodRadio" checked={gifcardMethodSelected === 'print'} onChange={(e) => { if (e.target.checked) { setGifcardMethodSelected('print') } }} /> Print my card now<br />
                    (Incentives can be changed once confirmed)
                </div>
                <div>
                    <div onClick={handleGifcardMethod} className="greenButton">Confirm</div>
                </div>
            </dialog>

            <dialog id="giftcardSendDialog">
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>Bank Incentives for Later by Closing out</td>
                            <td><img src="assets/close.png" onClick={handleGifcardSendClose} alt="Close" /></td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ textAlign: 'center' }}>Gifcard delivered<br />
                    <img src={"assets/gifcard" + selectedGifcard + ".png"} alt="Gifcard" /><br />
                    Gift Card amount ${Number(givenUdt).toFixed(2)} {GiftCard[selectedGifcard]}
                </div>
                <div style={{ padding: '10px', textAlign: 'center' }}>
                    <div style={{ display: gifcardMethodSelected === 'cell' ? '' : 'none' }}>Text was send to <br />{member.phoneNumber}</div>
                    <div style={{ display: gifcardMethodSelected === 'email' ? '' : 'none' }}>Email was send to <br />{member.email}</div>
                    <div style={{ display: gifcardMethodSelected === 'print' ? '' : 'none' }}>Ready to print<br />
                        <div>
                            <div onClick={handleGifcardPrintNow} className="greenButton">Print now</div>
                        </div>
                    </div>
                </div>

            </dialog>

            <dialog id="actionAlertDialog">
                <table width="100%">
                    <tbody>
                        <tr>
                            <td style={{textAlign:'center'}}><h1 style={{color:'#800000'}}>Action Required</h1></td>
                            <td><img src="assets/close.png" onClick={handleActionAlertClose} alt="Close" /></td>
                        </tr>
                    </tbody>
                </table>
                
                <div style={{textAlign:'center'}}>
                    Before making the next visit entry:
                </div>
                <div style={{textAlign:'center'}}>
                    Please attest to the Member's MediCal<br/>and CM Elegibility by clicking the &quot;Confirm button&quot;<br/>in the top right corner of the Member's chart
                </div>
            </dialog>

            <WrongTip />
            <Spinner loading={loading}></Spinner>
        </div>
    )
};