import { NavBar } from "../component/NavBar.jsx"
import { SideBar } from "../component/SideBar.jsx"

export const StateAnalyticsPage = ()=>{
    return (
        <div>
        <NavBar/>
        <SideBar selector="state-analytics"/>
        <div className="container">
            <h1>Analytics</h1>
            State analytics content </div>
        
        </div>
    )
};