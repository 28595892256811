
import { Link, useNavigate } from "react-router-dom";

export const IndexPage = ()=>{
    const navigate = useNavigate()
    return (
        <Link
        to="login"
        onClick={(e) => {
          e.preventDefault();
          navigate("login");
        }}
      >
        Page
      </Link>

    )
}