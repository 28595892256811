import axios from "axios";
import { ConfigApi } from "../Config";

const ADD_TRAINEE_ENDPOINT = `${ConfigApi.API_SERVER}/training/add-trainee`
const LIST_TRAINEE_ENDPOINT = `${ConfigApi.API_SERVER}/training/trainees`
const GET_TRAINEE_ENDPOINT = `${ConfigApi.API_SERVER}/training/trainee`
const LIST_MEMBER_ENDPOINT = `${ConfigApi.API_SERVER}/training/members`
const GET_MEMBER_ENDPOINT = `${ConfigApi.API_SERVER}/training/member`
const ADD_MEMBER_ENDPOINT = `${ConfigApi.API_SERVER}/training/add-member`
const LIST_PRACTICE_CASE_ENDPOINT = `${ConfigApi.API_SERVER}/training/practice-case`
const LIST_TEST_RESULT_ENDPOINT = `${ConfigApi.API_SERVER}/training/testresults`
const LIST_ROLES_BY_USER = `${ConfigApi.API_SERVER}/training/get-roles-by-user`

const LIST_COUNTIES_ENDPOINT = `${ConfigApi.API_SERVER}/training/counties`
const LIST_PROVIDERS_ENDPOINT = `${ConfigApi.API_SERVER}/training/providers`

const LIST_COORDINATORS_ENDPOINT = `${ConfigApi.API_SERVER}/training/coordinators`

const TEST_TRACK_ENDPOINT = `${ConfigApi.API_SERVER}/training/testtrack`

const LIST_USERS_ENDPOINT = `${ConfigApi.API_SERVER}/training/trainees?users=true`

const ADD_USER_ENDPOINT = `${ConfigApi.API_SERVER}/training/add-trainee`

const LIST_TEST_CASE_ENDPOINT = `${ConfigApi.API_SERVER}/training/testresults`

export const traineAdd = async (trainee) => {
    try {
        console.log('traineAdd.start', new Date().toISOString())
        const addTraineResponse = await axios.post(ADD_TRAINEE_ENDPOINT, trainee);
        console.log('traineAdd.end', new Date().toISOString())
        return addTraineResponse.data;
    } catch (error) {
        console.log(error);
    }
}

export const traineList = async () => {
    try {
        const listTrainiResponse = await axios.get(LIST_TRAINEE_ENDPOINT);
        return listTrainiResponse.data;
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const traineGet = async (practice_id) => {
    try {
        const getTrainiResponse = await axios.get(GET_TRAINEE_ENDPOINT + '?practice_id=' + practice_id);
        return getTrainiResponse.data;
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const practiceCaseList = async (traineeId) => {
    try {
        console.log('practiceCaseList.start', new Date().toISOString())
        const listPracticeCaseResponse = await axios.get(LIST_PRACTICE_CASE_ENDPOINT + '?trainee_id=' + traineeId);
        console.log('practiceCaseList.end', new Date().toISOString())
        return listPracticeCaseResponse.data;
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const memberAdd = async (member) => {
    try {
        console.log('memberAdd.start', new Date().toISOString())
        const addMemberResponse = await axios.post(ADD_MEMBER_ENDPOINT, member);
        console.log('memberAdd.end', new Date().toISOString())
        return addMemberResponse.data;
    } catch (error) {
        console.log(error);
    }
}

export const memberList = async (practice_id) => {
    try {
        const listTrainiResponse = await axios.get(LIST_MEMBER_ENDPOINT + '?practice_id=' + practice_id);
        return listTrainiResponse.data;
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const memberGet = async (user_id) => {
    try {
        const getTrainiResponse = await axios.get(GET_MEMBER_ENDPOINT + '?user_id=' + user_id);
        return getTrainiResponse.data;
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const testResultList = async (user_id) => {
    try {
        console.log('testResultList.start', new Date().toISOString())
        const listTestResultResponse = await axios.get(LIST_TEST_RESULT_ENDPOINT + '?user_id=' + user_id);
        console.log('testResultList.end', new Date().toISOString())
        return listTestResultResponse.data;
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const warnTestResult = async (testResultId, warn) => {
    try {
        const updateResponse = await axios.put(LIST_TEST_RESULT_ENDPOINT, {id:testResultId, warn});
        return updateResponse.data;
    } catch (error) {
        console.log(error);
    }
}

export const upadateTestResult = async (testresult) => {
    try {
        if (!testresult.amount) {
            testresult.amount = 0.0;
        }
        if (!testresult.comment) {
            testresult.comment = '';
        }
        console.log('TEST RESULT :: ', testresult)
        const updateResponse = await axios.put(LIST_TEST_RESULT_ENDPOINT, testresult);
        return updateResponse.data;
    } catch (error) {
        console.log(error);
    }
}

export const countyList = async () => {
    try {
        const listTestResultResponse = await axios.get(LIST_COUNTIES_ENDPOINT);
        return listTestResultResponse.data;
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const providerList = async (county_id) => {
    try {
        console.log('COUNTY UD ', county_id)
        if (county_id === '') {
            return [];
        }
        const listTestResultResponse = await axios.get(LIST_PROVIDERS_ENDPOINT + '?county_id=' + county_id);
        return listTestResultResponse.data;
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const providerFindById = async (practice_id) => {
    try {
        console.log('PROVIDER UD ', practice_id)
        const listTestResultResponse = await axios.get(LIST_PROVIDERS_ENDPOINT + '?provider_id=' + practice_id);
        return listTestResultResponse.data;
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const coordinatorList = async () => {
    try {
        const listCoordinatorResponse = await axios.get(LIST_COORDINATORS_ENDPOINT);
        return listCoordinatorResponse.data;
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const testTrack = async (testTrack) => {
    try {
        const testTrackResponse = await axios.post(TEST_TRACK_ENDPOINT, testTrack);
        return testTrackResponse.data;
    } catch (error) {
        console.log(error);
    }
}

export const getRolesByUser = async (email) => {
    const params = new URLSearchParams();
    params.append('email', email);
    try {        
        const response = await axios.get(LIST_ROLES_BY_USER+ '?' + params.toString());
        return response.data;
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const userAdd = async (trainee) => {
    try {
        console.log('traineAdd.start', new Date().toISOString())
        const addTraineResponse = await axios.post(ADD_USER_ENDPOINT, trainee);
        console.log('traineAdd.end', new Date().toISOString())
        return addTraineResponse.data;
    } catch (error) {
        console.log(error);
    }
}

export const userList = async () => {
    try {
        const listTrainiResponse = await axios.get(LIST_USERS_ENDPOINT);
        return listTrainiResponse.data;
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const testCaseList = async (testcase_id) => {
    try {
        const query = LIST_TEST_CASE_ENDPOINT + '?testcase_id=' + testcase_id;
        const listTestResultResponse = await axios.get(query);
        return listTestResultResponse.data;
    } catch (error) {
        console.log(error)
        return [];
    }
}