
export const Config = {
    TRAINEE_PRACTICE_ID:'65eb838b76e73e3bde86311d'  // DevTest by default
}

// https://optimum-vector-group.atlassian.net/wiki/spaces/Legacy/pages/235470850/Cookies
export const ConfigCookies = {
    USER_AUTHENTICATED : 'userAuthenticated',
    USER_ROLE:'userRole',
    USER_ID:'userId',
    /**
     * Selected trainee
     */
    TRAINEE_ID : 'traineeId',
    PRACTICE_CASE_ID : 'practiceCaseId',
    PRACTICE_CASE_STEPS : 'practiceCaseSteps',
    TRAINEE_STEP: 'traineeStep'
}

export const ConfigRoles = {
    TRAINEE:'TRAINEE',
    TRAINER:'TRAINER',
    ADMIN:'ADMIN'
}

export const ConfigSteps = {
    SAY_YES_UPD:'sayYesUpd',
    ADD_MEMBER: 'addMember',
    CLOSE_ADD_MEMBER:'closeAddMember',
    SEL_MEMBER:'selMember',
    SUBMIT_ADD_MEMBER:'submitAddMember',
    CONFIRM_GIFT_METHOD:'ConfirmGiftMethod',
    CLOSE_GIFT_METHOD:'closeGiftMethod',
    SUBMIT_EVENT:'submitEvent',
    PRINT_NOW:'printNow',
    ABSCENCE_SUBMIT:'abscenceSubmit',
    CLOSE_GIFT_SEND:'closeGiftSend',
    CLOSE_GIFT_SELECT:'closeGiftSelect',
    SELECT_EVENT:'selectEvent',
    TEST_NEXT_WEEK:'testNextWeek',
    REDEEM:'redeem',
    CLOSE_GIFT_CONFIRM:'closeGiftConfirm'
}

export const ConfigApi = {
    // API_SERVER:process.env.REACT_API_SERVER||'https://protected.primary.dhcs-training.dev.cmi-llc.com/v1',
    // API_SERVER:process.env.REACT_API_SERVER||'https://m5tgd3ix8l.execute-api.us-east-1.amazonaws.com/dev',
    API_SERVER:process.env.REACT_APP_API_SERVER ||'https://protected.primary.dhcs-training.dev.cmi-llc.com/v1',
    APP_USER_POOL_ID:process.env.REACT_APP_USER_POOL_ID ||'us-east-1_XuhRAk9Rx',
    APP_CLIENT_ID:process.env.REACT_APP_CLIENT_ID ||'6pg589mr1eu86ruprge9vupn5l'
}

export const ConfigStatus = {
    COMPLETED:'completed',
    PENDING:'pending',
    APPROVED:'approved',
    RESUME:'resume'
}

export const ConfigTestCaseStatus = {
    'completed':{
        color:'#408000',
        tab:'#c0c0c0',
        label:'Completed',
        enabled:false,
        evaluate:true
    },
    'approved':{
        color:'#ffd000',
        tab:'#ffd000',
        label:'Approved',
        enabled:false,
        evaluate:false
    },
    'pending':{
        color:'#ffffff',
        tab:'#ffffff',
        label:'Pending',
        enabled:true,
        evaluate:false
    },
    'resume':{
        color:'#c0c0c0',
        tab:'#ffffff',
        label:'Resubmission required',
        enabled:true,
        evaluate:false
    }
}

export const ConfigDelivery = {
    HOST:'https://testflight.tremendous.com',
    CREATE_ORDER_ENDPOINT:'/api/v2/orders',
    FOUNDING_ID:'QVPT3Z83Z8I9',
    TOKEN:'TEST_EPtP2RgBJ--jZteHvraPYdl_yhc17Bkum-Uc6v-WTn5',
    CAMPAIGN:'SFZZYKX1631X',
    PHONE_METHOD:'PHONE',
    EMAIL_METHOD:'EMAIL',
    LINK_METHOD:'LINK'
}
