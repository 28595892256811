import { CognitoUserPool } from 'amazon-cognito-identity-js';

import { ConfigApi } from '../Config';

const poolData = {
  UserPoolId: ConfigApi.APP_USER_POOL_ID,
  ClientId: ConfigApi.APP_CLIENT_ID,
};

const newCognitoPool = new CognitoUserPool(poolData);

export default newCognitoPool;