import { NavBar } from "../component/NavBar.jsx"
import { SideBar } from "../component/SideBar.jsx"

export const OarsAdminRolesPage = ()=>{
    return (
        <div>
        <NavBar/>
        <SideBar/>
        <div className="container">Oars Admin Roles content </div>
        
        </div>
    )
};