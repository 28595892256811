import { Link, useNavigate } from 'react-router-dom';
import { useAppContext } from "../context/AppContext";
import '../index.css'
import { ConfigCookies } from '../Config';
import { useCookies } from 'react-cookie';
import {dialogModal, dialogClose} from '../component/Dialog'

const NavBarItem = ({url, label, step})=>{
    const navigate = useNavigate();
    const { checkStep } = useAppContext();
    const handleClickNavBarItem = ()=>{
        if (checkStep('navigate'+step)){
            navigate(url)
        }
    }

    return (
        <span style={{cursor:'pointer'}} onClick={handleClickNavBarItem}> {label} </span>
    )
}

export const NavBar = ()=>{
    const navigate = useNavigate();
    const [cookies] = useCookies([ConfigCookies.TRAINEE_ID]);
    const imageUrl = `${process.env.PUBLIC_URL}/assets/dhcs-logo.png`;

    const handleConfirmRestart = (response)=>{
        if (response==='yes'){
            navigate("../testcase?trainee_id="+cookies.traineeId);
        }
        dialogClose('confimRestartTest');
    }

    return (
        <div className='navbar'>
            <div style={{'float':'left'}}>
                <img src={imageUrl} alt="DHCS" style={{'verticalAlign':'middle'}}/>
                CA DHCS Recovery Incentives Program</div>
            <div style={{'float':'right', 'verticalAlign':'bottom'}}>
                {cookies.traineeId && (<span style={{cursor:'pointer'}} onClick={()=>dialogModal('confimRestartTest')}>Trainee mode | </span>)}
                <NavBarItem url="../companies" label="Companies" step="Companies"/>
                <NavBarItem url="../events" label="Events" step="Events"/>
                <NavBarItem url="../companies" label="My Company" step="MyCompany"/>
                <NavBarItem url="../practices" label="Roles" step="Roles"/>
                <NavBarItem url="../oarsadminroles" label="User" step="Users"/>
                <NavBarItem url="../help" label="Help" step="Help"/>
                <Link to="../logout">Logout</Link>
            </div>

            <dialog id="confimRestartTest">
                <div style={{padding:'10px'}}>Are you sure restart the test case?</div>
                <button className="greenButton" onClick={()=>handleConfirmRestart('yes')}>Yes</button>
                <button className="redButton" onClick={()=>handleConfirmRestart('no')}>No</button>
            </dialog>

        </div>
        
    )
}