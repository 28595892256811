import "../index.css"
import { Link } from "react-router-dom"
import { ConfigCookies, ConfigRoles } from "../Config";
import { useCookies } from "react-cookie";


const SideItem = ({link, label, image, selector, roles})=>{
    const linkUrl = `../${link}`;
    const imageUrl = `${process.env.PUBLIC_URL}/assets/${image}.png`;
    const classDiv = (selector===link?'sideitemSel ':'') + 'sideitem';    
    
    const [cookies] = useCookies(ConfigCookies.USER_ROLE)

    console.log('Current user role', cookies.userRole);
    
    let showItem = false;

    roles.forEach(element => {
        if (element===cookies.userRole){
            showItem = true;
        }
    });

    return(showItem && (        
            <div className={classDiv}>
                <Link to={linkUrl}>
                    <img src={imageUrl} alt={label}/>
                    <div>{label}</div>
                </Link>
            </div>
        )
    )
    
}

export const SideBar = ({selector})=>{
    return (
        <div className="sidebar">            
            <SideItem link="dashboard" label="Dashboard" image="dhcs-dashboard" selector={selector} roles={[ConfigRoles.TRAINER]} />                            
            <SideItem link="trainer" label="Trainer" image="dhcs-dashboard" selector={selector} roles={[ConfigRoles.TRAINER]}/>

            <SideItem link="users" label="Manage Users" image="dhcs-manage" selector={selector} roles={[ConfigRoles.ADMIN]}/>
            
            {/* <SideItem link="state-analytics" label="State Analytics" image="dhcs-analytics" selector={selector}/>
            <SideItem link="county-analytics" label="County Analytics" image="dhcs-analytics" selector={selector}/>
            <SideItem link="provider-analytics" label="Provider Analytics" image="dhcs-analytics" selector={selector}/>
            
            <SideItem link="state-report" label="State Report" image="dhcs-reports" selector={selector}/>
            <SideItem link="provider-report" label="Provider Report" image="dhcs-reports" selector={selector}/>

            
            <SideItem link="admin" label="State Administrator" image="dhcs-manage" selector={selector}/> */}
        </div>
    )
}