import { NavBar } from "../../component/NavBar.jsx"
import { SideBar } from "../../component/SideBar.jsx"
import { ImgButton } from "../../component/ImgButton.jsx";
import { useCallback, useEffect, useState } from "react";
import { ConfigSteps, ConfigCookies } from "../../Config.js";
import { memberAdd, memberList, providerFindById, coordinatorList } from "../../service/TraineeService.js";
import { useAppContext } from "../../context/AppContext.jsx";
import { useNavigate } from "react-router-dom";
import { WrongTip } from "../../component/WrongTip.jsx";
import { strIsEmpty } from "../../service/Validate.js";
import { Spinner } from "../../component/Spinner.jsx";
import { useCookies } from "react-cookie";

const MEMBER_ADD_DIALOG = "memberAddDialogId";

const TablePatients = ({ members, handleAddMemberModal }) => {
    const { checkStep } = useAppContext();
    const navigate = useNavigate()
    const handleMemberClick = (memberId) => {
        if (checkStep(ConfigSteps.SEL_MEMBER)) {
            console.log('!!! ok step')
            navigate('../patient-weeks?user_id=' + memberId)
        } else {
            console.log('!!! wrong step')
        }
    }
    return (
        <table style={{ overflowY: "scroll", overflowX: "scroll", marginBottom: "50px" }} className="gridData">
            <thead>
                <tr>
                    <th rowSpan="2"><ImgButton handleClick={handleAddMemberModal} alt="Add member" asset="plus.png" /> Member</th>
                    <th rowSpan="2">Results<br />Entered</th>
                    <th rowSpan="2"> Week days<br />Remaining</th>
                    <th rowSpan="2">Week<br />Number</th>
                    <th rowSpan="2">CM Coordinator</th>
                    <th rowSpan="2">CM Start</th>
                    <th rowSpan="2">CM End</th>
                    <th rowSpan="2">Incentives<br />Earned</th>
                    <th rowSpan="2">Incentives<br />Received</th>
                    <th colSpan="6" className="sticky_header th-no-button-line">UDT Results</th>

                </tr>
                <tr>
                    <th>T</th>
                    <th style={{ color: "#EF3758" }}>P+</th>
                    <th style={{ color: "#00A827" }}>N-</th>
                    <th style={{ color: "#bb6700" }}>U</th>
                    <th style={{ color: "#6A7284" }}>E</th>
                    <th style={{ color: "#EF3758" }}>M</th>
                </tr>
            </thead>
            <tbody>
                {members.map((item) => (
                    <tr key={item._id}>
                        <td onClick={() => handleMemberClick(item._id)} className="linkTd" style={{ fontWeight: 'bold', cursor: 'pointer' }}>{item.firstName} {item.lastName}</td>
                        <td>{item.resultsEntered}</td>
                        <td className="gridDataNum">{item.weekDayRemaining}</td>
                        <td className="gridDataNum">{item.weekNumber}</td>
                        <td>{item.coordinator.name}</td>
                        <td>{item.start}</td>
                        <td>{item.end}</td>
                        <td className="gridDataNum">{item.incEarned}</td>
                        <td className="gridDataNum">{item.incReceived}</td>
                        <td className="gridDataNum">{item.udtT}</td>
                        <td className="gridDataNum">{item.udtP}</td>
                        <td className="gridDataNum">{item.udtN}</td>
                        <td className="gridDataNum">{item.udtU}</td>
                        <td className="gridDataNum">{item.udtE}</td>
                        <td className="gridDataNum">{item.udtM}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export const ProviderHomePaPage = () => {
    const [loading, setLoading] = useState(false)
    const { checkStep } = useAppContext();

    const [confirmProvider, setConfirmProvider] = useState(false)
    const [provider, setProvider] = useState({})


    const searhParams = new URLSearchParams(document.location.search);
    const practice = searhParams.get("practice_id");

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [clientId, setClientId] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [email, setEmail] = useState('')
    const [cell, setCell] = useState('')
    const [coordinator, setCoordinator] = useState('');
    const [coordinators, setCoordinators] = useState([]);
    const [addMemberError, setAddMemberError] = useState('');
    const [cookies] = useCookies([ConfigCookies.PRACTICE_CASE_ID])

    const [members, setMembers] = useState([]);

    const handleAddMemberModal = async () => {
        if (checkStep(ConfigSteps.ADD_MEMBER)) {
            console.log('Make add member')
            await fetchCoordinator()
            setFirstName('')
            setLastName('')
            setClientId('')
            setDateOfBirth('')
            setEmail('')
            setCell('')
            setCoordinator('')
            setConfirmProvider(false);
            setAddMemberError('');
            const dialog = document.getElementById(MEMBER_ADD_DIALOG);
            dialog.showModal();
        } else {
            console.log('Dont make add member')
        }
    }

    const fetchMembers = useCallback(async () => {
        setMembers(await memberList(practice));
    }, [practice])

    async function fetchCoordinator() {
        setCoordinators(await coordinatorList())
    }

    const fetchProvider = useCallback(async () => {
        setProvider(await providerFindById(practice))
    }, [practice]);

    useEffect(() => {
        fetchProvider();
        fetchMembers();

    }, [fetchMembers, fetchProvider])

    const handleSubmit = async () => {
        // Out of the check step, to avoid block on the flow, checkstep move to the next step if is ok
        if (coordinator===''){
            setAddMemberError('Must select a coordinator');
            return;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const intPhoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
        const phoneRegex = /^(?:\+?1[-. ]?)?\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/
        let validationRule = "validate";
        if (!strIsEmpty(email)) {
            if (!emailRegex.test(email)) {
                setAddMemberError('Invalid email address.');
                return;
            }
            validationRule += "Email"
        }
        if (!strIsEmpty(cell)) {
            if (!phoneRegex.test(cell) && !intPhoneRegex.test(cell)) {
                setAddMemberError('Invalid phone number. Must be minimal 10 digits.');
                return;
            }
            validationRule += "Cell"
        }

        if (!checkStep(validationRule)){
            return;
        }else{
            console.log('All ok with the data')
        }
        console.log('TestCase',cookies.practiceCaseId)
        if (checkStep(ConfigSteps.SUBMIT_ADD_MEMBER)) {
            const member = {
                firstName: firstName,
                lastName: lastName,
                clientId: clientId,
                dateOfBirth: dateOfBirth,
                email: email,
                phoneNumber: cell,
                coordinator: coordinator,
                practice: practice,
                testCaseId: cookies.practiceCaseId
            }
            setLoading(true)
            const dialog = document.getElementById(MEMBER_ADD_DIALOG);
            dialog.close();
            await memberAdd(member);
            await fetchMembers();
            setLoading(false)
        }
    }

    const handleAddMemberClose = async () => {
        if (checkStep(ConfigSteps.CLOSE_ADD_MEMBER)) {
            const dialog = document.getElementById(MEMBER_ADD_DIALOG);
            dialog.close();
        }
    }

    return (

        <div>
            <NavBar />
            <SideBar />
            <div className="container">
                <h1>{provider.name}</h1>
                <TablePatients members={members} handleAddMemberModal={handleAddMemberModal} />

            </div>

            <dialog open={false} id={MEMBER_ADD_DIALOG}>
                <div style={{ float: 'left' }}><h1>Add new Member</h1></div>
                <div style={{ float: 'right' }}><img src="assets/close.png" onClick={handleAddMemberClose} alt="Close" /></div>

                <form>
                    <table className="formContainer">
                        <tbody>
                            <tr>
                                <td>First Name<br /><input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} /></td>
                                <td>Last Name<br /><input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} /></td>
                            </tr>
                            <tr>
                                <td>Client ID Number (CIN)<br /><input type="text" value={clientId} onChange={(e) => setClientId(e.target.value)} /></td>
                                <td>Date Of Birth<br /><input type="date" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} /></td>
                            </tr>
                            <tr>
                                <td>Email (Optional)<br /><span className="littleLabel">Option to Receive Email Gift Cards</span><br /><input type="text" value={email} onChange={(e) => setEmail(e.target.value)} /></td>
                                <td>Cell (Optional)<br /><span className="littleLabel">Option to Receive Text Gift Cards</span><br /><input type="text" value={cell} onChange={(e) => setCell(e.target.value)} /></td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    CM Coordinator<br />
                                    <select style={{ 'width': '100%' }} value={coordinator} onChange={(e) => setCoordinator(e.target.value)}>
                                        <option key={-1} value="">Select Coordinator</option>
                                        {

                                            coordinators.map((item) => (
                                                <option key={item._id} value={item._id}>{item.name}</option>
                                            ))
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    I have verified this member is eligible for medi-cal and for CM.
                                    <input type="checkbox" checked={confirmProvider} value={confirmProvider} onChange={(e) => setConfirmProvider(e.target.checked)} /><br />
                                    Submit new Member
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ color: '#ff0000', textAlign: 'center' }}><b>{addMemberError}</b></td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'center' }}>
                                    <button type="button" disabled={!confirmProvider} onClick={handleSubmit} className="blueButton" style={confirmProvider ? { background: '#384df0' } : { background: '#6e7fff' }}>Submit</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>

            </dialog>
            <WrongTip />
            <Spinner loading={loading}></Spinner>
        </div>
    )
};