import { useNavigate } from "react-router-dom"
import { NavBar } from "../component/NavBar";
import { practiceCaseList, traineGet } from "../service/TraineeService";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import { ConfigCookies, ConfigTestCaseStatus } from "../Config";
import { Spinner } from "../component/Spinner";

const TestCaseItem = ({ labelTestCase, status, handleSelectCase }) => {
    const config = ConfigTestCaseStatus[status];

    const clickHandle = config.enabled ? handleSelectCase : () => { };
    const cssStatus = { 'border': '1px solid #c0c0c0', 'textAlign': 'center', 'padding': '20px', background: config.color };
    const labelStatus = "(" + config.label + ")";

    return (
        <td key={labelTestCase} style={cssStatus} onClick={clickHandle}>{labelTestCase}<br />{labelStatus}</td>
    );
}

export const TestPage = () => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies([ConfigCookies.TRAINEE_ID, ConfigCookies.PRACTICE_CASE_ID, ConfigCookies.PRACTICE_CASE_STEPS, ConfigCookies.TRAINEE_STEP]);

    const searhParams = new URLSearchParams(document.location.search);
    const traineeId = searhParams.get("trainee_id");
    const [practiceCases, setPracticeCases] = useState([])
    const [trainee, setTrainee] = useState([])

    console.log('Current Trainee Id', cookies.traineeId);

    const fetchPracticeCases = useCallback(async () => {
        setLoading(true)
        setTrainee(await traineGet(traineeId))
        setPracticeCases(await practiceCaseList(traineeId));
        setLoading(false)
    }, [traineeId])

    useEffect(() => {
        fetchPracticeCases();
    }, [fetchPracticeCases])

    const handleSelectCase = (testCase) => {
        setCookie(ConfigCookies.PRACTICE_CASE_ID, testCase)
        setCookie(ConfigCookies.TRAINEE_ID, trainee._id)

        practiceCases.forEach(item => {
            if (item._id === testCase) {
                console.log('Selected case', item._id)
                setCookie(ConfigCookies.PRACTICE_CASE_STEPS, item.steps);
                setCookie(ConfigCookies.TRAINEE_STEP, item.step);
                console.log('Step :: ', item.step)
            }
        }
        )
        navigate(`../pro-home-pa?practice_id=${trainee.practice}`);
    }

    return (
        <div>
            <NavBar />
            <div className="container">
                <h1>Select Practice Case</h1>
                <table width="100%">
                    <tbody key="9999">
                        <tr key="1">
                            {
                                practiceCases.map((item) => (
                                    <TestCaseItem key={item._id} labelTestCase={item.name} status={item.status} handleSelectCase={() => handleSelectCase(item._id)} />
                                ))
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
            <Spinner loading={loading} />
        </div>

    )
}