export const dialogModal = (dialogId)=>{
    const dialog = document.getElementById(dialogId)
    dialog.showModal();
}

export const dialogClose = (dialogId)=>{
    const dialog = document.getElementById(dialogId)
    if (dialog){
        dialog.close();
    }
    
}