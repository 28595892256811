import { NavBar } from "../component/NavBar.jsx"
import { SideBar } from "../component/SideBar.jsx"

export const StateReportPage = ()=>{
    return (
        <div>
        <NavBar/>
        <SideBar selector="state-report"/>
        <div className="container">
            <h1>Reports</h1>
            State report content </div>
        
        </div>
    )
};