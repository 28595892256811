import { NavBar } from "../../component/NavBar"
import { SideBar } from "../../component/SideBar"
import { ImgButton } from "../../component/ImgButton"
import { useCallback, useEffect, useState } from "react"
import { userAdd, userList } from "../../service/TraineeService"
import { useAppContext } from "../../context/AppContext"
import { Spinner } from "../../component/Spinner"
import { ConfigRoles } from "../../Config"
import { ValidationMessages, areComplete, validateEmail} from "../../service/Validate"

const ADD_TRAINI_DLG = 'addTrainiDialogId'

const ROLE_LIST = [
    {label:ConfigRoles.TRAINER, value:ConfigRoles.TRAINER },
    {label:ConfigRoles.ADMIN, value:ConfigRoles.ADMIN }
]

const UsersData = ({users, handleAddTrainiModal})=>{
    const [data, setData] = useState(users);
    const { isAuthorized } = useAppContext();
 
    if (!
        isAuthorized([ConfigRoles.ADMIN])){
        document.location.href="../login"
    }
    
    useEffect(() => {
        setData(users)
    }, [users]);

    return (<table className="gridData">
    <tbody>
        <tr>
            <th><ImgButton handleClick={handleAddTrainiModal} alt="Add user" asset="plus.png"/> Trainer / Admin</th>
            <th>Creation Date</th>
            <th>Trainees Asigned</th>
            <th>Role</th>
        </tr>
        
        {data.map((item) => (           
            <tr key={item._id}>
                <td>{item.name}</td>
                <td>{item.createdDate}</td>
                <td>{item.role===ConfigRoles.TRAINER?(item.assigned?item.assigned:0):'-'}</td>
                <td>{item.role}</td>
            </tr>
            
        ))}

    </tbody>
</table>)
}

export const TrainerListPage = ()=>{
    const [loading, setLoading] = useState(false)
    const [firstName , setFirstName] = useState('');
    const [lastName , setLastName] = useState('');
    const [email , setEmail] = useState('');
    const [role , setRole] = useState('');
    
    const [users, setUsers] = useState([]);
    const [errorMessage, setErrorMessage] = useState('')
    
    const fetchUsersData = useCallback(async ()=>{
        setLoading(true)
        setUsers(await userList());
        setLoading(false)
    },[])

    useEffect(()=>{
        fetchUsersData();
    }, [fetchUsersData])
    

    const handleSubmitAddUserModal = async ()=>{
        if (!areComplete([role,firstName,lastName,email])){
            setErrorMessage('All fields must be filled, please check');
            return;
        }
        if (!validateEmail(email)){
            setErrorMessage(ValidationMessages.EMAIL_INVALID);
            return;
        }
        setLoading(true)
        setErrorMessage('');
        const response = await userAdd({firstName, lastName, email:email.trim(), role})
        setLoading(false)
        if (response.error){
            setErrorMessage(response.error);
        }else{
            const dialog = document.getElementById(ADD_TRAINI_DLG)
            dialog.close();
            fetchUsersData();
        }
    }
    const handleAddUserModal = async ()=>{
        const dialog = document.getElementById(ADD_TRAINI_DLG)
        dialog.showModal();
        setErrorMessage('');
        setFirstName('')
        setLastName('')
        setEmail('')
        setRole('');
    }

    return(
        <div>
            <NavBar/>
            <SideBar selector={'dashboard'}/>
            <div className="container">
                <h1>Manage Users</h1>

                <UsersData users={users} handleAddTrainiModal={handleAddUserModal}/>

            
            <dialog open={false} id={ADD_TRAINI_DLG} style={{zIndex:"100"}}>
                <h1>Add Trainer / Admin</h1>
                <table className="formContainer">
                    <tbody>
                        <tr><td>First Name :</td><td><input type="text" value={firstName} onChange={(e)=>setFirstName(e.target.value)}/></td></tr>
                        <tr><td>Last Name :</td><td><input type="text" value={lastName} onChange={(e)=>setLastName(e.target.value)}/></td></tr>
                        <tr><td>Email :</td><td><input type="text" value={email} onChange={(e)=>setEmail(e.target.value)} /></td></tr>
                        <tr><td>Role :</td><td><select value={role} onChange={(e)=>setRole(e.target.value)} >
                                    <option value="">Select a Role</option>
                                    {
                                        ROLE_LIST.map((item)=>(
                                            <option value={item.value}>{item.label}</option>
                                        ))
                                    }
                                </select></td></tr>
                        <tr><td colSpan={2} style={{color:"red",textAlign:"center"}}>{errorMessage}</td></tr>
                    </tbody>
                </table>                
                <div style={{textAlign:'center'}}>
                <button type="button" className='greenButton' onClick={handleSubmitAddUserModal}>Submit</button>
                </div>
                <Spinner loading={loading}></Spinner>
            </dialog>
            </div>
            
        </div>
    )
}