const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const ValidationMessages  = {
    EMAIL_INVALID : 'Invalid email address.'
}

export const validateEmail = (email)=>{
    return emailRegex.test(email);
}

export const strIsEmpty = (str)=>{
    if (str===undefined){
        return true;
    }
    const validStr = str.trim();
    if (validStr===''){
        return true;
    }
    return false;
}

export const areComplete = (values)=>{
    let result = true;
    values.forEach(value => {
        if (strIsEmpty(value)){
            result = false;
        }
    });
    return result;
}