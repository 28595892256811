import { NavBar } from "../component/NavBar.jsx"
import { SideBar } from "../component/SideBar.jsx"

export const ProviderAnalyticsPage = ()=>{
    return (
        <div>
        <NavBar/>
        <SideBar selector="provider-analytics"/>
        <div className="container">
            <h1>Analytics</h1>
            
            Provider analytics content </div>
        
        </div>
    )
};