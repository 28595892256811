import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import { ConfigCookies } from "../Config";
import { useEffect } from "react";
import { logout } from '../service/authenticate';

export const LogoutPage = ()=>{

    const Navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(ConfigCookies.USER_ROLE, ConfigCookies.TRAINEE_ID, ConfigCookies.USER_AUTHENTICATED)

    useEffect(()=>{
        console.log ('Current Status', cookies);
        setCookie(ConfigCookies.TRAINEE_ID, undefined)
        removeCookie(ConfigCookies.USER_AUTHENTICATED)
        removeCookie(ConfigCookies.USER_ROLE)
        logout();
        Navigate('../login')
    })
    

    return (
        <div>
        Leaving from DHCS
        
        </div>
    )
};