
import {dialogModal, dialogClose} from '../component/Dialog'

const WRONG_TIP_ID = "wrongTipDlgId";

export const showWrongTip = (message)=>{
    const dialog = document.getElementById(WRONG_TIP_ID);
    if (dialog){
        dialog.innerHTML = message;
        dialogModal(WRONG_TIP_ID)
    }
}

export const WrongTip = () => {
    
    const handleClose = ()=>{
        dialogClose(WRONG_TIP_ID)
    }
    return (
        <dialog style={{background:'#ff0000', color:'#ffffff',cursor:'pointer'}} open={false} id={WRONG_TIP_ID} onClick={handleClose}></dialog>
    )
}

